import { IDataUserRole, IUserRole } from '../../types';

const convertRole = (role: IDataUserRole) => {
    const { id, code, name, is_external } = role;
    return { roleId: id, roleCode: code, roleName: name, isExternal: is_external } as IUserRole;
};

const convertUserRoles = (roles: IDataUserRole[]) => roles.map(role => convertRole(role));

export default convertUserRoles;
export { convertRole };
