import { IConfigurationDetail, IConfigurationDetailProduct, IDataConfigurationDetail, IDataConfigurationDetailProduct } from '../../types';

const convertConfigurationDetailData = ({
    user_id,
    tax_id,
    count,
    client_name,
    partner_name,
    erp_bill_status,
    created_at,
    items,
    erp_bill_number,
    is_draft,
    app_id,
    pending_event,
    doc_sheet_name,
    is_virtual,
    type_config_virtual,
    show_details,
    tkp_id,
    ...rest
}: IDataConfigurationDetail) => {
    const listConfDetailProducts: IConfigurationDetailProduct[] = items?.map((product: IDataConfigurationDetailProduct) => {
        const { pricelist_id, count, ...rest } = product;
        return { ...rest, articul: pricelist_id, amount: count };
    });

    const configuration: IConfigurationDetail = {
        userId: user_id,
        taxId: tax_id,
        amount: count,
        clientName: client_name,
        partnerName: partner_name,
        ERPUploadStatus: erp_bill_status,
        creationDate: created_at,
        items: listConfDetailProducts,
        billNumber: erp_bill_number,
        isDraft: is_draft,
        appId: app_id,
        pending: pending_event,
        sheetName: doc_sheet_name,
        isVirtual: is_virtual,
        typeConfigVirtual: type_config_virtual === 'C' ? null : type_config_virtual === 'V' ? 'В' : 'К',
        showDetails: show_details ?? false,
        tkpId: tkp_id,
        ...rest,
    };

    return configuration;
};

export default convertConfigurationDetailData;
