import React from 'react';
import Header from '../header';
import ScrollButton from '../scroll-button';

interface IProps {
    children: any;
}

const LayoutMain = ({ children }: IProps) => {
    return (
        <section className="layout-main">
            <Header />
            <section className="layout-main__container">{children}</section>
            <ScrollButton />
        </section>
    );
};

export default LayoutMain;
