import React from 'react';
import Nav from '../nav';

interface IProps {
    toggle: (e: React.MouseEvent<HTMLElement>) => void;
}

const LayoutAside = ({ toggle }: IProps) => {
    return (
        <section className="layout-aside" onClick={e => toggle(e)}>
            <div className="layout-aside__nav">
                <Nav />
            </div>
        </section>
    );
};

export default LayoutAside;
