import styled from '@emotion/styled';
import React from 'react';
import { OptionProps } from 'react-select';
import { IConfigurationFormProduct, ISelectOption } from '../../assets/ts/types';
import { ONLY_FOR_PRESALE_TAG_TEXT } from '../../modules/configurator-form/assets';

const COLOR_FOCUSED = 'rgba(217, 217, 217, 0.26)';
const COLOR_SELECTED = 'rgba(55, 105, 151, 0.33)';
const COLOR_ACCENT = '#1890ff';
const COLOR_DANGER = '#d91745';
const COLOR_ALERT_INFO_TEXT = '#0c5460';
const COLOR_ALERT_INFO_BG = '#d1ecf1';
const COLOR_ALERT_INFO_BORDER = '#bee5eb';

const Wrapper = styled.div<{ isFocused: boolean; isSelected: boolean }>`
    padding: 9px 11px 12px;
    display: flex;
    gap: 21px;
    background: ${props => {
        let bg = 'transparent';
        if (props.isFocused) bg = COLOR_FOCUSED;
        if (props.isSelected) bg = COLOR_SELECTED;
        return bg;
    }};

    cursor: ${props => (!props.isSelected && props.isFocused ? 'pointer' : 'auto')};

    .select-label {
        flex: 1;
    }

    .select-tag {
        display: inline-flex;
        align-items: center;
        padding: 1px 7px 2px;
        margin-top: 2px;
        margin-bottom: 2px;
        border-radius: 4px;
        border: '2px solid ' + ${COLOR_ALERT_INFO_BORDER};
        background-color: ${COLOR_ALERT_INFO_BG};
        color: ${COLOR_ALERT_INFO_TEXT};
        white-space: nowrap;
        font-size: 10px;
    }

    .select-info {
        color: ${COLOR_ACCENT};
        white-space: nowrap;
    }

    .select-error {
        color: ${COLOR_DANGER};
        white-space: nowrap;
    }

    .select-price {
        flex: none;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .select-price__dash {
        margin-right: 20px;
    }
`;

const Option = (props: OptionProps<IConfigurationFormProduct & ISelectOption>) => {
    const { data: option, isSelected, isFocused, innerRef, innerProps, label } = props;
    const { name: optionName, nameHtml: optionNameHtml, price: optionPrice, noPrice, amountInStock, showAmountInStock, onlyForPresale } = option;
    const price = optionPrice ? optionPrice.toLocaleString() : 'Нет цены';

    return (
        <Wrapper isSelected={isSelected} isFocused={isFocused} ref={innerRef} {...innerProps}>
            <div className="select-label">
                {optionNameHtml && !optionNameHtml.includes('<script') ? (
                    <span dangerouslySetInnerHTML={{ __html: optionNameHtml }} />
                ) : (
                    optionName || label
                )}
                <br />
                {onlyForPresale && <div className="select-tag _presale">{ONLY_FOR_PRESALE_TAG_TEXT}</div>}
                {showAmountInStock &&
                    (amountInStock ? (
                        <div className="select-info">на складе {amountInStock} шт.</div>
                    ) : (
                        <div className="select-error">нет на складе</div>
                    ))}
            </div>

            {!noPrice && (
                <div className="select-price">
                    <div className="select-price__dash">&mdash;</div>
                    <div className="select-price__price">
                        {price} {!!optionPrice && ' $'}
                    </div>
                </div>
            )}
        </Wrapper>
    );
};

export default Option;
