import { AnyAction, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import applicationReducer from './reducers/app';
import categoriesReducer from './reducers/categories';
import configurationsReducer from './reducers/configurations';
import productsReducer from './reducers/products';
import routerReducer from './reducers/router';
import sideNavReducer from './reducers/side-nav';
import userReducer from './reducers/user';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['app'],
    stateReconciler: autoMergeLevel2,
};

const appPersistConfig = {
    key: 'app',
    storage,
    whitelist: ['currentApp', 'userSettings'],
};

const appReducer = combineReducers({
    app: persistReducer(appPersistConfig, applicationReducer),
    router: routerReducer,
    user: userReducer,
    products: productsReducer,
    categories: categoriesReducer,
    sideNav: sideNavReducer,
    configurations: configurationsReducer,
});

type RootState = ReturnType<typeof rootReducer>;
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'app/clearStorage') {
        console.warn('Истекла сессия/необходимо обновить стор.');
        storage.removeItem('persist:root');

        const { router } = state;
        state = { router } as RootState;
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const syncStateMiddleware = [createStateSyncMiddleware({ blacklist: ['persist/PERSIST', 'persist/REHYDRATE'] })];

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(syncStateMiddleware),
    devTools: true,
});

initMessageListener(store);

export const persistor = persistStore(store);
