import { StylesConfig } from 'react-select';

const BG_COLOR_SELECTED = 'rgba(236, 236, 236, 0.6)';
const COLOR_LIGHT = '#cfcfcf';
const COLOR_REGULAR = '#7d7d7d';
const COLOR_DANGER = '#d91745';
const TRANSITION_DURATION = '0.2s';

const styles: StylesConfig<any> = {
    control: (provided, { isDisabled, isMulti }) => ({
        ...provided,
        border: '1px solid #ececec',
        minHeight: isMulti ? '38px' : '32px',
        height: isMulti ? 'auto' : '32px',
        backgroundColor: isDisabled ? BG_COLOR_SELECTED : 'transparent',

        ':hover': {
            borderColor: COLOR_LIGHT,
        },
    }),
    valueContainer: provided => ({
        ...provided,
    }),
    input: provided => ({
        ...provided,
        margin: 0,
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: '100%',
    }),
    menu: provided => ({
        ...provided,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        margin: '2px 0 0',
        width: 'calc(100% + 2px)',
        border: '1px solid rgba(203, 203, 203, 1)',
        borderTop: 'none',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    }),
    menuList: provided => ({
        ...provided,
        padding: 0,
    }),
    option: (provided, { selectProps }) => {
        const creatableOptionLabelClassName = `.${selectProps.classNamePrefix ?? 'select'}__option-new`;
        return {
            ...provided,
            cursor: 'pointer',

            [creatableOptionLabelClassName]: {
                display: 'flex',
                alignItems: 'center',
            },

            [`${creatableOptionLabelClassName}-value`]: {
                padding: '3px 6px',
                margin: '0 5px',
                backgroundColor: BG_COLOR_SELECTED,
                borderRadius: '2px',
                fontSize: '85%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        };
    },
    singleValue: (provided, { isDisabled }) => ({
        ...provided,
        color: isDisabled ? 'rgb(84, 84, 84)' : 'inherit',
    }),
    multiValue: provided => ({
        ...provided,
        backgroundColor: BG_COLOR_SELECTED,
    }),
    multiValueRemove: provided => ({
        ...provided,
        transition: TRANSITION_DURATION,
        cursor: 'pointer',

        svg: {
            fill: COLOR_LIGHT,
            transition: TRANSITION_DURATION,
        },

        ':hover': {
            backgroundColor: 'transparent',
        },

        ':hover svg': {
            fill: COLOR_DANGER,
        },
    }),
    clearIndicator: provided => ({
        ...provided,
        transition: TRANSITION_DURATION,
        cursor: 'pointer',

        svg: {
            fill: COLOR_LIGHT,
            transition: TRANSITION_DURATION,
        },

        ':hover svg': {
            fill: COLOR_REGULAR,
            opacity: 0.6,
        },
    }),
};

export default styles;
